$form-blue: #1069d1;

// Braintree Hosted Fields overrides
.braintree-hosted-fields-focused {
  // TODO: check if theme is needed here
  border: 2px solid $form-blue !important;
}

// react-modal overrides

.ReactModal {
  &__Overlay {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    overflow: hidden;
  }
}

.ReactModal__Overlay {
  transition: opacity 250ms ease-in-out;
}
