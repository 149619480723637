$light-gray: #f6f6f6;
$dark-gray: #222222;

body {
  margin: 0;
  font-family: "font-local", "font-primary", "Helvetica Neue", "Helvetica",
  "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: $dark-gray;
}

a {
  text-decoration: none;
  color: $dark-gray;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.is-checkout {
  background-color: $light-gray;
}
