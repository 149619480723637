$green: #00883f;
$z-index-modal: 10001;

/* Contents of nprogress/nprogress.css with less styles :) */
#nprogress {
  pointer-events: none;

  .bar {
    background: $green;

    position: fixed;
    z-index: $z-index-modal + 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $green, 0 0 5px $green;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }
}
